.ai-suggestion-container {
    margin: 5px 5px 0px 5px
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 8px;

    .spinner-btn-style {
        border: 1px solid #D4D4D4;
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }
}
.ask-ai-submit-button{
    cursor: pointer;
    width: 35px;
}

.answer-container {
    margin-top: 8px;
}