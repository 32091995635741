.button-style {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-white);
    background-color: var(--color-dodgerBlue);
    border: 1px solid var(--color-dodgerBlue);
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    height: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &:disabled {
        cursor: not-allowed;
        color: var(--color-darkGray);
    }
}

.solid-blue {
    color: var(--color-white);
    background-color: var(--color-dodgerBlue);
    border: 1px solid var(--color-dodgerBlue);


    &:disabled {
        background-color: var(--color-solitude);
        border-color: var(--color-solitude);
    }
}

.solid-whiteSmoke {
    color: var(--color-black);
    background-color: var(--color-whiteSmoke);
    border: 1px solid var(--color-whiteSmoke);

    &:disabled {
        background-color: var(--color-white);
        border-color: var(--color-solitude);
    }
}

.solid-green {
    color: var(--color-lightCyan);
    background-color: var(--color-waterCourse);
    border: 1px solid var(--color-waterCourse);

    &:disabled {
        background-color: var(--color-solitude);
        border-color: var(--color-solitude);
    }
}