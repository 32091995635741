.explain-code-ai-container {
    margin: 5px 5px 0px 5px;
    position: relative;
}

.refresh-explain-code-ai {
    position: absolute;
    top: -23px;
    right: 20px;
}

.ask-question-container {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.ask-question-container .explain-code-input {
    flex: 1;
    width: inherit;
}

.ask-question-container .send-btn {
    padding: 10px;
}