.popup-container {
    border-radius: 8px;
    padding: 10px 5px;
    min-height: 165px;
    background-color: var(--color-white);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}