.spinner {
  border-radius: 50%;
  animation: spin 2s linear infinite;
  border: 0.2rem solid #E3EDFF;
  border-top: 0.2rem solid #0052CC;
}

.extra-small {
  width: 12px;
  height: 12px
}

.small {
  width: 25px;
  height: 25px;
}

.medium {
  width: 35px;
  height: 35px
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
