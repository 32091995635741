.input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 14px;
    margin-bottom: 4px;
}

.input-field, .textarea-field {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    font-size: 12px;
    max-width: 100%;
    outline: none;
    padding: 4px 8px;
    width: 96%;
}

.textarea-field {
    resize: none;
    padding: 10px;
}