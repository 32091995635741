.icon-button-style {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0px;

    &:disabled {
        cursor: not-allowed;
    }

}