#nected-editor {
  height: calc(100vh - 3px);
}

.nected-editor-container {
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - 3px);
}

.nected-editor-top-menu {
  background: #dfe8f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-left: 1px solid #e4e1e1;
  padding: 10px;
}

.nected-editor-top-menu .top {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-family: system-ui;
  font-size: 12px;
}

.execute-button {
  color: #fff;
  padding: 5px 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  gap: 5px;
  background: rgb(6, 150, 14);
}

.nected-formula-button {
  color: #fff;
  padding: 5px 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  gap: 5px;
  background: #2d7cff;
  opacity: 1 !important;
  cursor: pointer !important;
}

.nected-formula-button svg,
.nected-formula-button path {
  opacity: 1 !important;
  cursor: pointer !important;
}

.nected-editor-top-menu .bottom {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nected-editor-top-menu .right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.ace-tm .ace_gutter {
  background: #fafafa !important;
}

.jsonEditorContainer {
  padding: 1rem;
}

html,
body {
  padding: 0px;
  margin: 0px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

:root {
  --color-lightGray: #D4D4D4;
  --color-darkGray: grey;
  --color-dodgerBlue: #2D7CFF;
  --color-egyptianBlue: #0F4296;
  --color-black: #000;
  --color-white: #fff;
  --color-whiteSmoke: #efefef;
  --color-solitude: #EBEDF1;
  --color-lightCyan: #d3ffea;
  --color-waterCourse: #008847;
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.nected-logo {
  width: 20rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-noerror {
  width: 100vw;
  background: green;
  height: 3px;
}

.editor-error {
  width: 100vw;
  background: #d53636;
  height: 3px;
}

.nected-icon {
  max-width: 100%;
  top: 10px;
  right: 10px;
  width: 20px;
}

#ask-ai {
  position: absolute;
  left: 0px;
  top: 0px;
  visibility: visible;
  z-index: 10;
  font-weight: 600;
  color: var(--color-black);
  background-color: var(--color-white);
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  letter-spacing: 0em;
  box-shadow: 0px 0px 16px 0px #00000026;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#explain-code-ai {
  color: var(--color-black);
  background-color: var(--color-white);
  cursor: pointer;
  border-radius: 2px;
  letter-spacing: 0em;
  padding: 2px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#ask-ai>img,
#explain-code-ai>img {
  width: 15px;
}

#ask-ai-popup {
  position: absolute;
  z-index: 11;
  width: 50vw;
  max-height: 20rem;
  max-width: 35rem;
  overflow-y: auto;
  left: 50px;
  box-shadow: 0px 0px 16px 0px #00000026;
  border-radius: 8px;
}

#explain-code-ai-popup {
  position: absolute;
  z-index: 11;
  width: 50vw;
  max-height: 20rem;
  max-width: 35rem;
  overflow-y: auto;
  top: 50px;
  right: 10px;
  box-shadow: 0px 0px 16px 0px #00000026;
  border-radius: 8px;
}

.ask-ai {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-color: #888;
  z-index: 5;
  font-family: Lato;
}

#token-tooltip {
  background: beige;
  color: #050505;
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  max-width: 400px;
  overflow: scroll;
  max-height: 150px;
  display: none;
  min-width: 40px;
}

span.ace_paren.ace_lparen+span.ace_punctuation.ace_operator+span.ace_identifier+span.ace_punctuation.ace_operator+span.ace_identifier.ace_paren.ace_rparen {
  /* Your styles here */
  color: red;
}

.ace_editor.ace_autocomplete {
  width: 400px !important;
}

.ace_nected_token {
  color: #2d7cff;
}

.ace_nected_helper_function {
  color: #0539e4;
}


.popup-container .title {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0pc;
  font-weight: 600;
  gap: 10px;
  font-size: 14px;
}

.popup-container .title img {
  width: 15px;
}

.grey {
  color: var(--color-darkGray)
}

.ace_fn_rest_api_connector {
  color: #a63377;
}

.ace_connector_name {
  color: #7c8491;
}

.ace_select_key,
.ace_select_list_token,
.ace_select_date_token,
.ace_select_unit {
  background-color: #16b862;
  color: #fff;
  border-radius: 6px;
  font-size: 13px;
}

.non-editable {
  color: gray;
  /* Change the text color to make it look disabled */
  pointer-events: none;
  /* Disable pointer events to prevent clicking and editing */
  user-select: none;
  /* Prevent text selection */
}

.ace_rest_api_connector {
  background-color: #16b862;
  color: #fff;
  padding: 2px;
  border-radius: 6px;
  font-size: 13px;
}

#context-menu,
#token-menu {
  font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, "Source Code Pro",
    source-code-pro, monospace;
  z-index: 200000;
  border: 1px lightgray solid;
  position: fixed;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  background: #fefefe;
  color: #111;
  -webkit-user-select: none;
  user-select: none;
  cursor: text;
  font-size: 12px;
  display: none;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  border-radius: 8px;
}

#context-menu .sub-item-menu li,
#token-menu .sub-item-menu li {
  cursor: default;
}

#context-menu .parentItem:hover,
#token-menu .parentItem:hover {
  cursor: default;
  background-color: #cad7fa;
}

#context-menu .sub-item-menu li:hover,
.single-item:hover,
#token-menu .sub-item-menu li:hover {
  cursor: default;
  background-color: #cad7fa;
}

.parentItem {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.parentItem .icon {
  display: contents;
}

.single-item {
  padding: 0 10px;
  font-size: 13px;
}

.menu-back:hover {
  background: rgb(99 99 99);
  color: #fff;
}

.menu-back {
  padding: 2px 5px;
  background: rgb(99 99 99);
  cursor: default;
  text-align: center;
  color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.menu-title {
  padding: 4px 10px;
  background: #160b9d;
  text-align: center;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.list-wrapper {
  max-height: 120px;
  overflow-y: scroll;
}

pre {
  margin: 0px;
  padding: 0px;
}

.close_tooltip {
  display: flex;
  justify-content: right;
  margin-bottom: 5px;
  font-size: 16px;
  cursor: pointer;
}

.exec_icon {
  margin: 0px;
}

.key-datatype {
  font-size: 8px;
  color: grey;
}

.display-mode-container {
  bottom: 7px;
  right: 10px;
  display: flex;
  font-size: 12px;
  font-family: monospace;
  cursor: pointer;
  background: #ffff;
  border-radius: 2px;
}

.display-mode-container>div {
  padding: 5px 16px;
}

.selected-display-mode {
  background: #1156fe;
  color: #fff !important;
  border-radius: 2px;
}

.display-mode-gui {
  cursor: pointer;
  border-top-right-radius: 2px;
  color: #6d6b6b;
  border-bottom-right-radius: 2px;
}

.display-mode-code {
  cursor: pointer;
  border-top-left-radius: 2px;
  color: #6d6b6b;
  border-bottom-left-radius: 2px;
}


.json-editor-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100%;
  overflow: scroll;
  height: 100%;
  width: 100%;
}

.json-editor-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100%;
  border-radius: 5px;
}

.json-editor-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  border-radius: 5px;
  padding: 5px 15px;
  width: 98%;
  position: relative;
}

.json-editor-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  font-family: system-ui;
  font-size: 0.8rem;
  width: 100%;
  padding: 0.5rem;
}


/* .value-obj + .json-editor-row{
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #F7F8F9;
  padding: 0.5rem;
} */

input,
select {
  padding: 0rem 0.5rem;
  border: 1px solid #e4e1e1;
  border-radius: 5px;
  color: #949292;
  height: 30px;
  font-size: 12px !important;
  width: 180px;
}

select {
  width: 200px;
}

.value-obj {
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px 0px;
  border-radius: 3px;
  border: none;
  background: none;
}

.key-value {
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #F7F8F9;
  padding: 0.5rem;
  width: auto;
}

.array-value {
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #F7F8F9;
  padding: 0.5rem;
  width: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.array-empty-key {
  width: 174px;
}

.key-value>input {}

.value-obj>input {
  margin-bottom: 1rem;
}

.value-editor-container {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.data-type-pill {
  background: #dfe8f6;
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1156fe;
  text-transform: capitalize;
  min-width: 90px;
}

.key-editor-container {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.keys-details-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.key-add {
  border: none;
  color: white;
  background: green;
  border-radius: 10%;
  width: 24px;
  height: 24px;
  line-height: 1px;
  cursor: pointer;
}

.add-new-key {
  border: none;
  color: #2D7CFF;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  outline: none;
  margin-top: 10px;
}

.node-ellipsis {
  display: inline-block;
  color: rgb(203, 75, 22);
  font-size: 18px;
  line-height: 10px;
  cursor: pointer;
}

.object-items-text {
  color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: default;
}

.object-notation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.add-button-parentKey {
  display: none;
  cursor: pointer;
}

.add-button-title:hover>.add-button-parentKey {
  display: inline;
  color: rgb(203, 75, 22);

}

.key-delete {
  border: none;
  background: none;
  line-height: 1px;
  cursor: pointer;
}

.json-editor-add-new-row {
  display: flex;
  gap: 5px;
  align-items: center;
}

.json-editor-add-new-row>select {
  padding: 0rem 0.5rem;
  border: 1px solid #e4e1e1;
  border-radius: 5px;
  color: #949292;
  height: 30px;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  cursor: pointer;
  outline: none;
}

.DropdownMenuItem {
  outline: none;
  padding: 3px;
}

.DropdownMenuItem:hover {
  background: #eee;
  border-radius: 5px;
}

.disabled-top-menu {
  cursor: not-allowed;
  background: #eee;
}

.accordion-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #F7F8F9;
  padding: 0px 0.5rem;
  height: 50px;
}

.accordion-icon {
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.accordion-content {
  width: 100%;
}

.total-keys {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.accordian-vertical {
  position: absolute;
  height: 24px;
  width: 2px;
  background: #E0E0E0;
  left: 27px;
  rotate: 90deg;
  bottom: 13px;
}

.accordian-horizontal {
  position: absolute;
  height: calc(100% - 80px);
  width: 2px;
  background: #E0E0E0;
  left: 15px;
  top: 56px;
}

.react-datalist-input__listbox-option {
  font-size: 10px !important;
  word-break: break-all !important;
}

mark {
  font-size: 10px !important;
}

.react-datalist-input__textbox {
  width: 200px !important;
}

.react-datalist-input__container {
  width: auto !important;
}

.react-datalist-input__listbox {
  max-height: 18rem;
  overflow-y: scroll;
}

.key-editor-container>.container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.apply-margin {
  margin-left: 2rem;
}

.remove-margin {
  margin-left: 0rem;
}

.key-delete-array {
  border: none;
  background: none;
  line-height: 1px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.array-key {
  height: 48px;
  line-height: 55px;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  font-family: system-ui;
  font-size: 14px;
}

.help-menu {
  background: #fff;
  display: flex;
  font-size: 15px;
  gap: 11px;
  padding: 4px 10px;
  border-radius: 2px;
  color: #1156fe;
  font-family: system-ui;
}

.help-menu-divider {
  border: 1px solid #eee;
}

.help-menu>div>span {
  font-size: 12px;
}

.help-menu>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.tooltip-message {}

.execute-output-container {
  position: relative;
  border: 0px;
  background: #fff;
  height: 5vh;
  z-index: 10;
  width: calc(100vw);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: system-ui;
  font-size: 13px;
}

.execute-heading {
  padding: 5px;
  background: rgb(223, 232, 246) !important;
  display: flex;
  gap: 11px;
  align-items: center;
  justify-content: flex-start;
}

.execute-output-container-close {
  position: absolute;
  right: 10px;
  top: 4px;
  padding: 0px 2px;
  cursor: pointer;
}

.execute-output-container-minimize {
  position: absolute;
  right: 53px;
  font-size: 20px;
  top: 3px;
  cursor: pointer;
}

.execute-output-container>.response {
  padding: 5px;
  overflow-y: scroll;
  height: calc(50vh - 35px);
}

.execution-time {
  font-size: 10px;
}

.editor-info-container {
  position: relative;
  /* top: 44px; */
  width: 100vw;
  z-index: 9;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: system-ui;
  font-size: 13px;
  /* overflow-x: scroll; */
}

.editor-info-content {
  padding: 0.5rem;
}

.language-comments {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  padding: 11px;
  background: #eee;
  border-radius: 5px;
  color: #7e7c7c;
}

.nected-tokens {
  color: #2d7cff;
}

.two-section-info-container {}

.two-section-info-container .heading {
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 10px;
}

.two-section-info-container .info-items {
  color: #717171;
  display: flex;
  gap: 5px;
  padding: 5px;
}

.two-section-info-container .info-items .container-one {
  overflow: auto;
  max-height: calc(50vh + 25px - 100px);
  background: #fdfdfd;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 20%;
}

.two-section-info-container .items-description {}

.two-section-info-container .info-items .category {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

.two-section-info-container .info-items .category .category-name {
  font-weight: 600;
}

.two-section-info-container .info-items .category .formulas {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.2rem;
  margin-left: 10px;
}

.two-section-info-container .info-items .category .formulas .formula-name,
.two-section-info-container .info-items .category .formulas .selected-formula-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 5px;
}

.two-section-info-container .info-items .category .formulas .formula-name:hover {
  background-color: #eee;
  border-radius: 5px;
}

.two-section-info-container .info-items .category .formulas .selected-formula-name {
  background-color: #2e7cff;
  border-radius: 5px;
  color: #fff;
}

.two-section-info-container .info-items .category .formulas .formula-name:hover {}

.two-section-info-container .info-items .category .formulas span {}

.two-section-info-container .info-items .container-two {
  overflow: auto;
  max-height: 300px;
  padding: 0rem 1rem;
  border-radius: 5px;
  width: 80%;
  max-height: calc(50vh + 25px - 65px);
}

.two-section-info-container .info-items .container-two .formula-name {
  display: flex;
  gap: 5px;
  font-size: 14px !important;
  font-weight: 600;
  color: #000 !important;
  align-items: center;
}

.two-section-info-container .info-items .container-two .formula-description {
  display: block;
  margin-top: 6px;
}

.methods-desc-section {}

.code-section {
  display: flex;
  gap: 1rem;
}

.code-section>code {
  display: flex;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ea8698;
  justify-content: space-between;
  font-size: 12px;
  flex-direction: column;
  gap: 5px;
}

.icons-container {
  display: none;
}

.code-section:hover>.icons-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.execute-icon {
  color: rgb(6, 150, 14);
}

.examples-des-container .sample-value {
  margin-top: 1.5rem;
  background: #eee;
  padding: 5px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.examples-des-container .sample-value select {
  padding: 3px;
  border: none;
  background: #dadada;
  color: #000;
  height: auto;
  width: auto;
  outline: none;
  cursor: pointer;
  background: #63bb68;
  color: #fff;
}

.examples-des-container .sample-value select:hover {
  background: #63bb68;
  color: #fff;
}

.disabled-menu>.top * {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled-menu-switch {
  cursor: not-allowed;
  opacity: 0.5;
}

.suggestions-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li.active,
.suggestions-list li:hover {
  background-color: #eee;
}

.search-bar-container {
  position: relative;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-wrapper div {
  width: 100%;
  border: none;
  padding: 13px 10px;
  background: #dfe8f6;
  outline: none;
  color: #000;
  font-size: 14px !important;
  border-radius: 0px;
  border: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: #aaa;
  top: 8px;
  background: #1156fe;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.formula-output {
  padding: 10px 10px;
  border-bottom: 1px solid #eee;

}

.formula-output-success {
  background-color: #16b8623b;
}

.formula-output-error {
  background-color: #ff000024;
}

.ace_tooltip {
  white-space: normal !important;
}